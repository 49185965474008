import axios from 'axios'
import qs from 'qs'

const API_URL = 'http://localhost:4000/auth'



//Register user
const register = async(user) => {
    const response = await axios.post(API_URL+'/signup',qs.stringify(user))
    if(response && response.data){
        localStorage.setItem('user',JSON.stringify(response.data.user));
        localStorage.setItem('access_token',response.data.access_token);
    }
    return response.data;
}


//Login user
const login = async(user) => {
    const response = await axios.post(API_URL+'/signin',qs.stringify(user))
    if(response && response.data){
        localStorage.setItem('user',JSON.stringify(response.data.user));
        localStorage.setItem('access_token',response.data.access_token);
    }
    return response.data;
}



//logout
const logout = async() => {
    localStorage.removeItem(
        'user',
        'accesss_token'
    )
}

const authService = {
    register,
    login,
    logout
}

export default authService