import React from 'react'

import {Link} from 'react-router-dom';
import './NotFound.css'

export default function NotFound() {
  return (
    <div className='notfound'>
        <p> Please return back later, web site is under maintenance!</p>
        <p>
          {/*   <Link to={'/'}>
            عد الى الصفحة الرئيسية من هنا !
            </Link>*/}
        </p>
    </div>
  )
}
