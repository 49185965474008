
class Actuator {

    //service;
   
   constructor(name,tel,state,speciality,service,img){
       
       this.name=name;
       this.phoneNumber=tel;
       this.img = img;
       this.state = state || 'تونس';
       this.service = service;
       this.speciality = speciality;
       //this.service=service;
   }

   

   

}

const  Actuators =[
       new Actuator('Clinique vétérinare sahloul','73820275 - 98536471 - 2627580','سوسة','Veterinare',1,'https://scontent.ftun9-1.fna.fbcdn.net/v/t1.6435-9/181621050_818690569078150_8851334833701779641_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=Wg-uPcwh5TYAX-u3jEx&_nc_ht=scontent.ftun9-1.fna&oh=00_AT9tugN7mu3xJcllKxsFS7G8Ki641sDrGxDy9mkc0k-IOw&oe=6292CB6D'),
       new Actuator('VETOPIA','20501105','صفاقس','Veterinare',1,''),
       new Actuator('Baha Eddine Gouader','96103340 - 28508590 -76270837','ڤفصة','Veterinare',1,'https://scontent.ftun15-1.fna.fbcdn.net/v/t1.15752-9/279226062_1696013697428474_6861975543058194822_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=ae9488&_nc_ohc=wN8ziBs0Q5MAX8NuIz5&tn=JdGpwbMh2cDkFunH&_nc_ht=scontent.ftun15-1.fna&oh=03_AVKq0-0h00eYgXOw_l1v1Nb1itWgE-iYu-CcMVEFnEkgmQ&oe=62918522'),
       new Actuator('Ela Sahmim','99200552','بنزرت','Veterinare',1,'https://scontent.ftun15-1.fna.fbcdn.net/v/t1.15752-9/278781876_1378091476039831_3511493147541264809_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=ae9488&_nc_ohc=Dh5BMn7fi48AX9IBSh9&_nc_ht=scontent.ftun15-1.fna&oh=03_AVJrAw6doeOzclpckcf6LIlxS2-r1d_n8mIRb8ORbFS6jQ&oe=62903299'),
       new Actuator('Marwen Zayati','71556865 - 50982126','أريانة','Veterinare',1,'https://scontent.ftun15-1.fna.fbcdn.net/v/t1.15752-9/278532614_995238194715195_2530066132924058715_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=ae9488&_nc_ohc=b3DgVacFXlAAX-uRT0h&_nc_ht=scontent.ftun15-1.fna&oh=03_AVLAalQi6qXbIpMY7Kae9y_o9jTeK2b4pnwEbOsl5SeUaA&oe=62905566'),
       new Actuator('Amira Zaier','22881514 - 51588586','أريانة','Veterinare',1,''),
       new Actuator('شركة عمر خالد لحفر الآبار','98329864','بن_عروس','المياه',3),
       new Actuator('Mustapha Mechani','23 434 888','سوسة','Ingenieur en hydraulique',3),
       new Actuator('Mabrouka','71366112 - 71366812','بن_عروس','Pepiniere',2,'https://scontent.ftun9-1.fna.fbcdn.net/v/t1.18169-9/10402443_272782282904054_3519083673890204097_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=XmP6BFoyax8AX_7YB6O&_nc_ht=scontent.ftun9-1.fna&oh=00_AT-NhbSpnNrKFpDL6HDE_TQEU3z-3eXClfSmy3Y7D3NY_A&oe=628FC888'),
       new Actuator('Ben Cheikh Garden','24916012','تونس','Pepiniere',2,'https://scontent.ftun9-1.fna.fbcdn.net/v/t1.18169-9/10250134_636648779742302_6667747796079819653_n.jpg?_nc_cat=106&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=vdajKgL2zsIAX9vAAKG&_nc_oc=AQnvnImLwzpF_I3hAhP2HaTbxmLGvWFoc5vZ2PKmeJ9NpRwAT8y-RDdkROo6tKZpeUo&_nc_ht=scontent.ftun9-1.fna&oh=00_AT8o3r7uF3MkM1C1q0izVM7EsEq8ECRkqQT4xAS2lNaDXQ&oe=629071D2'),
       new Actuator('SMBG','71366090','بن_عروس','Pepiniere',2,'https://scontent.ftun9-1.fna.fbcdn.net/v/t1.6435-9/70443214_748808052206649_3060846170487128064_n.png?_nc_cat=100&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=N617Drfln2IAX8ShFo4&_nc_ht=scontent.ftun9-1.fna&oh=00_AT_rOLZe_xqAVjsC9ELs_3eHu73icq_S_vE3Xn_C3fDtow&oe=6290F21D'),
       new Actuator('Pepiniere Casa Verde','95389512','أريانة','Pepiniere',2,'https://scontent.ftun9-1.fna.fbcdn.net/v/t39.30808-6/262501387_6533483050058564_2695785340779555986_n.jpg?_nc_cat=108&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=9T51b7pyhSoAX_xdC0L&_nc_ht=scontent.ftun9-1.fna&oh=00_AT8IlPoqDRMg3xY41qAoU7ddjsT_7-KmUzexFmHqhvlhwQ&oe=6270EF39'),
       new Actuator('دار الفلاح','22376354 ','الكاف','Pieces de rechange',2,"https://scontent.ftun9-1.fna.fbcdn.net/v/t1.6435-9/125317662_2764051857202755_7541711181740581439_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=Xb8FPW9mFJYAX_fXY8f&_nc_ht=scontent.ftun9-1.fna&oh=00_AT-5DsUo2rlG7AtpPvTRjNGW8M7Z3Fa65uH7iaPfDjkU-A&oe=62928985"),
       new Actuator('منير خماخم','28999909','سوسة','Ingenieur Agricole',2,'https://scontent.ftun9-1.fna.fbcdn.net/v/t1.6435-9/39514781_1827604787354079_7703176368254615552_n.jpg?_nc_cat=106&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=nb_LlkqEhvAAX9q8j0e&_nc_ht=scontent.ftun9-1.fna&oh=00_AT8O1PNmjcnwi69JWJ_4M3aDcqn8vBszDzYHbYn6BHQxEw&oe=6291142E'),
       new Actuator('كمال عبيدي','53851454','سوسة','Ingenieur',2,''),
       new Actuator('عيسى عروس','53186586','صفاقس','مهندس في الإنتاج النباتي و مختص في علوم التربة',2,'https://scontent.ftun16-1.fna.fbcdn.net/v/t1.15752-9/279092358_746504576516246_582553617613246473_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=ae9488&_nc_ohc=dWBwwmurYjkAX9qMzLS&_nc_ht=scontent.ftun16-1.fna&oh=03_AVLaRK47Se9t2Pk6f5N1zPNL4IHSoe396YOIl7kueosRYw&oe=62933387'),
       new Actuator('ربيعة فضلاوي','92650193','باجة','Ingenieur',2,''),
       new Actuator('مقاولة رؤوف الجبالي','97030533','بنزرت','Ingenieur',2,''),
       new Actuator('Hamadi Charoufi','98561496','بنزرت','Ingenieur',2,''),
       new Actuator('مقاولة محمد الربيع الزين','98283082','جندوبة','Ingenieur',2),
       new Actuator('Monia Baccouche','98919799','ڤفصة','المياه',3,''),
       new Actuator('ضو بن علي خشوم','98507844','مدنين','المياه',3,''),
       new Actuator('شركت خدمات وصيانة الآبار الفلاحة','98433236','مدنين','المياه',3,''),
       new Actuator('زياد السعيدي','98825357','توزر','المياه',3,''),
       new Actuator('أنيس بن الطاهرفيلالي','25527550','مدنين','المياه',3,''),
       new Actuator('مكرم بن الصادق بوبكر','52382190','المنستير','المياه',3,''),
       new Actuator('صالح ضيف اللّه','96048361','المهدية','Machines et produits agricoles',4,''),
       new Actuator('Hmida Bichiou','53934367','تونس','المياه',3,'https://scontent.ftun15-1.fna.fbcdn.net/v/t1.15752-9/278756840_699346808039909_6712330443732702416_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=ae9488&_nc_ohc=p7Yno9jgRsEAX_Ix4mL&_nc_ht=scontent.ftun15-1.fna&oh=03_AVKc9Y4GQKZutUSz7D7wT2sBAQzh6y4okHGtOCTlVkwVRg&oe=6292DE71'),
       new Actuator('Agrisud','58425000','المهدية','Veterinare',2),
       new Actuator('Root Irrigation','21628509005','بن_عروس','Vente pesticides et engrais',2,'https://scontent.ftun15-1.fna.fbcdn.net/v/t1.15752-9/278756840_699346808039909_6712330443732702416_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=ae9488&_nc_ohc=p7Yno9jgRsEAX_Ix4mL&_nc_ht=scontent.ftun15-1.fna&oh=03_AVKc9Y4GQKZutUSz7D7wT2sBAQzh6y4okHGtOCTlVkwVRg&oe=6292DE71'),
       new Actuator('BECOSA','72333395 - 72333506 - 98349809','نابل','Etudes et ingénierie agricole',2,''),
       
];


export default Actuators